import Head from 'next/head';
import Link from 'next/link';

export const Main = (props) => {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
      </Head>
      <main>
        {props.hostname !== 'xn--billigatgresor-sib.se' && (
          <div className="tabs">
            <ul>
              <li>
                <Link href="/">Hem</Link>
              </li>
              <li>
                <Link href="/search/">Sök</Link>
              </li>
            </ul>
          </div>
        )}
        {props.children}
      </main>
      <footer className="footer">
        <div className="content">
          <nav className="level">
            <div className="level-item">
              <figure className="image is-64x64">
                <img src="/images/db.svg" alt="Deutsche Bahn" title="Deutsche Bahn" />
              </figure>
            </div>
            <div className="level-item">
              <figure className="image is-64x64">
                <img src="/images/dsb.svg" alt="Danske Statsbanern" title="Danske Statsbaner" />
              </figure>
            </div>
            <div className="level-item">
              <figure className="image is-64x64">
                <img src="/images/sj.svg" alt="Statens Järnvägar" title="Statens Järnvägar" />
              </figure>
            </div>
            <div className="level-item">
              <figure className="image is-64x64">
                <img
                  src="/images/obb.svg"
                  alt="Austrian Federal Railways"
                  title="Austrian Federal Railways"
                />
              </figure>
            </div>
          </nav>
        </div>
      </footer>
    </>
  );
};
